import { Message } from '@ks/kwai-ui';
import { RES_CODE } from '@/types/global';
import { LANGUAGE, LANGUAGE_STORAGE_KEY } from '@/common/enum';

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [option] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */
export default function request(url: string, option: any) {
    const sysLanguage = localStorage.getItem(LANGUAGE_STORAGE_KEY) || LANGUAGE.en;
    // 有个改变的筛选项数据
    const options = {
        ...option,
    };

    const newOptions: RequestInit = {
        credentials: 'include',
        mode: 'cors',
        ...options,
    };
    const commonHeaders = {
        Accept: 'application/json',
        sysLanguage,
        ...newOptions.headers,
    };
    if (newOptions.method === 'POST' || newOptions.method === 'PUT' || newOptions.method === 'DELETE') {
        if (!(newOptions.body instanceof FormData)) {
            newOptions.headers = {
                'Content-Type': 'application/json; charset=utf-8',
                ...commonHeaders,
            };

            newOptions.body = JSON.stringify(newOptions.body);
        } else {
            // newOptions.body is FormData
            newOptions.headers = {
                ...commonHeaders,
                ...newOptions.headers,
            };
        }
    } else {
        newOptions.headers = {
            ...commonHeaders,
            ...newOptions.headers,
        };
    }

    return fetch(url, newOptions)
        .then((response: any) => {
            if (newOptions.method === 'DELETE' || response?.status === 204) {
                return response.text();
            }
            return response.json();
        })
        .then((response: any) => {
            // 如果权限获取失败，被拦截等问题
            const pathname = window.location.pathname;
            // 避免死循环
            if (pathname !== '/login') {
                if (response.result === RES_CODE.NEED_LOGIN) {
                    Message.error('Please login');
                    window.open('/login', '_self');
                }
                if (response.result === RES_CODE.UN_AUTHORIZED) {
                    Message.error(response?.msg || 'No authorization');
                }
            }
            return response;
        });
}

export function http(apiPromise: any, option: any, config = {} as any) {
    return new Promise((resolve, reject) => {
        apiPromise(option, config).then((res: any) => {
            // server端：res.result = 1
            // openAid: !res.data && res.result
            if (res.result || (!res.data && res.result)) {
                resolve(res);
            } else {
                resolve({
                    ...res,
                    data: {},
                });
            }
        });
    });
}
