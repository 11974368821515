import { Commit } from 'vuex';
import { http } from '@/lib/request';
import { getCommonSelectorsApi } from '@/services';

const getCommonSelectorsData = 'getCommonSelectorsData';
const setCommonSelectorsData = 'setCommonSelectorsData';
interface Selectors {
    [key: string]: any;
}

function getLocalStorageSelector(): Selectors {
    const str: string | null = localStorage.getItem('commonSelectors');
    const obj = (str && JSON.parse(str)) || {};
    return obj;
}

function setLocalStorageSelector(obj: Selectors) {
    const str = obj && JSON.stringify(obj);
    str && localStorage.setItem('commonSelectors', str);
}

export default {
    namespaced: true,
    state: {
        getCommonSelectorsData: getLocalStorageSelector(),
    },
    mutations: {
        async [getCommonSelectorsData](state: any, option: any) {
            const obj = {
                ...getLocalStorageSelector(),
            };
            const response: any = await http(getCommonSelectorsApi, option);
            Object.assign(obj, response?.data);

            Object.assign(state[getCommonSelectorsData], obj);
            setLocalStorageSelector(obj);
        },
        [setCommonSelectorsData](state: any, option: any) {
            const obj = {
                ...getLocalStorageSelector(),
            };

            Object.assign(state[getCommonSelectorsData], option);
            setLocalStorageSelector(obj);
        },
    },
    actions: {
        [getCommonSelectorsData]({ commit }: { commit: Commit }, payload: any) {
            commit('getCommonSelectorsData', payload);
        },
        [setCommonSelectorsData]({ commit }: { commit: Commit }, payload: any) {
            commit('setCommonSelectorsData', payload);
        },
    },
};
