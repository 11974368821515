import { GET, POST } from '@/lib/dataProxy';

export * from './publishApiModule';
export * from './poolApiModule';
export * from './translateApiModule';

export const getUserInfoApi = GET()`/api/intelligent/user/info`; // 获取用户信息(权限等，在登录后，调用它)
export const getUserSendMessageApi = POST()`/api/intelligent/user/sendMessage`; // 发送验证码
export const getUserVerifyMsgCodeApi = POST()`/api/intelligent/user/verifyMsgCode`; // 校验验证码
export const getKconfSettingApi = GET()`/api/intelligent/common/getConfigs`; // kconf
export const setUserTypeApi = POST()`/api/intelligent/user/setUserType`; // 设置用户类型
export const ssoInfraLogout = GET()`/rest/infra/logout`; // logout
export const getMicroApps = '/api/intelligent/common/user/microApps'; // 获取微应用app信息
export const getMicroAppsApi = GET()`${getMicroApps}`; // 获取微应用app信息api
