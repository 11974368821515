import { stringify } from 'qs';
import request from '@/lib/request';

// 上传接口参数处理
export const formRequestData = (params: any) => {
    const keys = Object.keys(params);
    const fd = new FormData();
    for (const key of keys) {
        fd.append(key, params[key]);
    }
    return fd;
};

// 简化 Api 请求
function joinArray(arr1: TemplateStringsArray, arr2: string[]) {
    const res = [];
    for (let i = 0; i < arr1.length; i++) {
        arr1[i] && res.push(arr1[i]);
        arr2[i] && res.push(arr2[i]);
    }
    return res.join('');
}

export const GET =
    <Params, Resp>(preParams = {}) =>
    (string: TemplateStringsArray, ...val: any) => {
        return async (params: Params = {} as Params, config: any = {}) => {
            const api = joinArray(string, val);
            return request(
                `${api}?${stringify({ ...preParams, ...params })}`,
                {
                    headers: {
                        ...config.headers,
                    },
                },
                config
            ) as Promise<any> as Promise<Resp>;
        };
    };

export const POST =
    <Params, Resp>(preParams = {}) =>
    (string: TemplateStringsArray, ...val: any) => {
        return async (params: Params = {} as Params, config: any = {}) => {
            const api = joinArray(string, val);

            return request(
                api,
                {
                    body: { ...preParams, ...params },
                    headers: {
                        ...config.headers,
                    },
                    method: 'POST',
                },
                config
            ) as Promise<any> as Promise<Resp>;
        };
    };

export const Form =
    <Params, Resp>() =>
    (string: TemplateStringsArray, ...val: any) => {
        return async (params: Params = {} as Params, config: any = {}) => {
            const api = joinArray(string, val);
            return request(
                api,
                {
                    body: formRequestData(params),
                    headers: {
                        ...config.headers,
                    },
                    method: 'POST',
                },
                config
            ) as Promise<any> as Promise<Resp>;
        };
    };
