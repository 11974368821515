<template>
    <ProForm ref="formRef" :items="items" :data="formData" :rules="rules" />
</template>

<script setup lang="ts">
import { reactive, ref, inject, watch } from 'vue';
import { ProForm } from '@kibt/pro-render';
import { Message } from '@ks/kwai-ui';
import { getUserSendMessageApi } from '@/services';
import { RES_CODE } from '@/types/global';

const $k18n: any = inject('$k18n');
const formRef = ref(null);
const props = defineProps({
    formData: {
        type: String,
        default: '',
    },
});

const items = reactive([
    {
        type: 'array',
        title: '',
        items: [
            [
                {
                    type: 'input',
                    key: 'kwaiId',
                    label: 'App ID',
                },
            ],
        ],
    },
]);
const rules = {
    kwaiId: [{ required: true, message: $k18n('请输入App ID') }],
};
async function validate() {
    const { valid } = await formRef.value?.validate();
    if (valid) {
        const { result, msg } = await getUserSendMessageApi(props.formData);
        if (result === RES_CODE.SUCCESS) {
            Message.success($k18n('验证码发送成功'));
        } else {
            Message.error(msg || 'send message error');
        }
        return {
            valid: result === RES_CODE.SUCCESS,
        };
    }
    return {};
}
defineExpose({
    validate,
});
</script>

<style scoped lang="scss"></style>
