import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { VueK18nNext } from '@k18n/vue-k18n-next';
import k18nPkg from '@k18n/operation-creative';
import KwaiUi from '@ks/kwai-ui';
import { init } from '@ks/general-sso';
import enLocale from '@ks/kwai-ui/lib/locale/lang/en';
import zhLocale from '@ks/kwai-ui/lib/locale/lang/zh-CN';
import { createOperationTrack } from '@kibt/pro-lib';
import { HotUpdate } from '@k18n/hot-update';
import store from '@/oldVuexStore';
import App from './App.vue';
import { $weblog } from '@/lib/weblog';
import '@kibt/pro-theme/lib/index.css';
import '@/assets/scss/index.scss';
import router from '@/router';
import proWidget from '@/components/tableWidget/index';
import { getDefaultLocals } from '@/stores/locals';
import { LANGUAGE, SSO } from '@/common/enum';
import { getBaseUrl } from '@/common/env';
import { setMicroConfig } from './lib/microSetting';

const app = createApp(App);
// 屏蔽警告信息
app.config.warnHandler = () => null;
setMicroConfig(router);

// 登录
init({
    sid: SSO.SID,
    appName: SSO.SID,
    env: 'production', // 'development',production
    language: getDefaultLocals(),
    baseUrl: getBaseUrl(),
    callback: '',
});
// 内部会使用Provide注入$k18n
app.use(VueK18nNext, {
    locale: getDefaultLocals(),
    messages: k18nPkg,
    disableWarning: true,
    plugins: [new HotUpdate()],
});
app.use(KwaiUi, {
    locale: {
        [LANGUAGE.en]: enLocale,
        [LANGUAGE.zh]: zhLocale,
    }[getDefaultLocals()],
});
app.use(createPinia());
app.use(router);
app.use(store);

createOperationTrack(app, $weblog.weblog, {
    injectDirective: true,
});

app.config.globalProperties.$PROCONFIG = {
    $k18n: app.config.globalProperties.$k18n,
    widget: proWidget,
};
app.mount('#app');
