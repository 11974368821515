import { defineStore } from 'pinia';
import { LANGUAGE_STORAGE_KEY, LANGUAGE } from '@/common/enum';

export const getDefaultLocals = (): string => {
    return localStorage.getItem(LANGUAGE_STORAGE_KEY) || navigator.language.split('-')[0] || LANGUAGE.en;
};

const setLocalStorageLocals = (value: string) => {
    localStorage.setItem(LANGUAGE_STORAGE_KEY, value);
};

export const useLocalStore = defineStore('local', {
    state: () => ({
        locals: getDefaultLocals(),
        langs: [
            {
                value: LANGUAGE.zh,
                label: '中文(简体)',
            },
            {
                value: LANGUAGE.en,
                label: 'English',
            },
			{
                value: LANGUAGE.ar,
                label: 'العربية',
            },
			{
                value: LANGUAGE.es,
                label: 'Español',
            },
			{
                value: LANGUAGE.id,
                label: 'Bahasa',
            },
			{
                value: LANGUAGE.pt,
                label: 'português',
            },
			{
                value: LANGUAGE.tr,
                label: 'Türkçe',
            },
        ],
    }),
    actions: {
        setLocal(locals) {
            const langsValues = this.langs.map(item => item.value);
            let result = locals;
            // 设置语言有误，为英文
            if (!langsValues.includes(locals)) {
                result = LANGUAGE.en;
            }
            this.locals = result;
            setLocalStorageLocals(result);
        },
    },
});
