import { GET, POST, Form } from '@/lib/dataProxy';

const newServer = '/api/intelligent/';
export const configListApi = POST()`/api/intelligent/translate/taskStrategy/list`;// 配置列表
export const configDetailApi = POST()`/api/intelligent/translate/taskStrategy/info`;// 配置详情接口
export const configUpdateApi = POST()`/api/intelligent/translate/taskStrategy/insertOrUpdate`;// 配置更新接口
export const getOrgListApi = POST()`/api/intelligent/translate/taskStrategy/bucketAgency/list`;// 机构列表接口
export const getVerticalTypeApi = POST()`/api/intelligent/translate/taskStrategy/verticalType/list`;// 用机构桶查询垂类列表接口
export const getAgencyAndTranslatorListApi = POST()`/api/intelligent/translate/taskStrategy/agencyTranslator/list`;// 机构下译制员列表接口
export const getAllTranslatorListApi = GET()`/api/intelligent/translate/taskStrategy/translator/list`;// 全量译制员搜索列表接口
export const changeStrategyStatusApi = GET()`/api/intelligent/translate/taskStrategy/switchStrategy`;// 策略上下线
export const getUserVerticalTypeApi = POST()`/api/intelligent/translate/taskStrategy/getVerticalTypeList`;// 用用户查询所属垂类列表接口


// 老译制接口
export const getTaskListApi = GET()`${newServer}translate/task/list`;//
export const statisticsApi = GET()`${newServer}translate/task/statistics`;//
export const discardApi = GET()`${newServer}translate/task/discard`;//
export const mmuMakeVideoApi = GET()`/api/intelligent/translate/photo/makeVideo`;// 视频合成接口
export const mmuPreStepApi = GET()`/api/intelligent/translate/photo/preStep`;// 返回待翻译状态
export const photoDetailApi = GET()`/api/intelligent/translate/photo/photoInfo`;// 译制页面视频详情
export const confirmSubmitApi = GET()`/api/intelligent/translate/photo/confirmSubmit`;// 确认翻译接口
export const saveTranslateDraftApi = POST()`/api/intelligent/translate/photo/mmuUpdate`;// 保存翻译草稿
export const mmuDetailApi = GET()`/api/intelligent/translate/photo/mmuDetail`;// 获取翻译字幕内容
export const translateTtsApi = GET()`/api/intelligent/translate/photo/ttsCompound`;// tts生成接口



export const baseInfoApi = GET()`${newServer}translate/user/baseInfo`;//
export const userInfosApi = POST()`${newServer}translate/user/infos`;//
export const userAddApi = POST()`${newServer}translate/user/add`;//
export const updateInfosApi = POST()`${newServer}translate/user/updateInfos`;//
export const highlightStatisticsApi = GET()`${newServer}translate/user/highlightStatistics`;//
export const taskStatisticsApi = POST()`${newServer}translate/user/taskStatistics`;//
export const downloadTaskStatisticsApi = POST()`${newServer}translate/user/downloadTaskStatistics`;//
export const agencyInfoApi = POST()`${newServer}translate/user/agencyInfo`;//


export const downloadTaskStatisticsUrlApi = `${newServer}translate/user/downloadTaskStatistics`;
export const getBgmListApi = GET()`api/intelligent/common/getBgmConfigs`;
// user
export const getCommonSelectorsApi = POST()`${newServer}translate/operations/config`;
