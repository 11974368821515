<template>
    <Layout />
</template>
<script setup>
import { useRouter } from 'vue-router';
import { watch } from 'vue';
import Layout from '@/layout/index.vue';
import { useGlobalStore } from '@/stores';
import { PlatformVersionEnum } from '@/stores/global';

const globalStore = useGlobalStore();
const router = useRouter();

watch(
    // 是否能打开新版页面的第一条件是有没有页面权限,
    () => globalStore.versionBtnShow,
    (val, oldVal) => {
        if (val && router.currentRoute.value.path.includes('cutmotionMicro/reactTest')) {
            globalStore.platformVersion = PlatformVersionEnum.NEW;
        }
    },
    { deep: true }
);
</script>
