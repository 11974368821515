<template>
    <router-view v-if="state.globalLayout" v-loading="globalStore.globalLayoutLoading" />
    <template v-else>
        <ks-container v-loading="state.loading">
            <ks-header class="layout_header" height="56px">
                <Header />
            </ks-header>
            <ks-container class="layout_container__wrapper">
                <template v-if="menuConfigs?.length !== 0">
                    <ks-aside width="200px" class="aside">
                        <Menu />
                    </ks-aside>
                    <ks-main>
                        <router-view />
                    </ks-main>
                </template>
                <Empty v-if="menuConfigs?.length === 0" />
            </ks-container>
        </ks-container>
    </template>
    <BindingIdDialog ref="bindingIdDialogRef" />
</template>

<script setup lang="ts">
import { computed, onMounted, reactive, ref, watch } from 'vue';
import { debounce } from 'lodash';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import Header from './header/index.vue';
import { useGlobalStore } from '@/stores';
import Menu from './menu/Menu.vue';
import Empty from '@/components/Empty.vue';
import { responsiveEnum } from '@/common/enum';
import BindingIdDialog from './bindingIdDialog/index.vue';

const store = useStore();
const route = useRoute();
const globalStore = useGlobalStore();
const bindingIdDialogRef = ref();
const state: any = reactive({
    globalLayout: false,
    loading: false,
    needBindId: false,
});

const menuConfigs = computed(() => {
    return globalStore?.userInfo?.menus || [];
});
onMounted(async () => {
    handleResize();
    window.addEventListener('resize', handleResize);
    setGlobalLayout();
    if (!globalStore.isInit) {
        // 进入
        await loadInit();
    }
    checkNeedBindId();
});
watch(
    () => route.path,
    () => {
        if (globalStore.isInit) {
            // 已经初始化拿到info信息的页面的路由变化时，例如登入成功后的跳转，不会重新刷新页面，所以要改变状态字段来重新渲染
            checkNeedBindId();
            setGlobalLayout();
        }
    }
);
const handleResize = debounce(() => {
    globalStore.isMobileMode = window.innerWidth <= responsiveEnum.m;
}, 500);
async function loadInit() {
    const pathname = window.location.pathname;
    if (!['/login', '/signup', '/password'].includes(pathname)) {
        await getUserInfo();
        getKconfSet();
    }
}
// 获取用户信息，获取不到，会跳登录页避免死循环
async function getUserInfo() {
    state.loading = true;
    await globalStore.getUserInfo();
    globalStore.isInit = true;
    state.loading = false;
}
// 获取全局kconf配置
async function getKconfSet() {
    await globalStore.getAllConfig();
    store.dispatch('selectorsModules/getCommonSelectorsData');
}
function setGlobalLayout() {
    const pathname = window.location.pathname;
    state.globalLayout = ['/login', '/signup', '/password'].includes(pathname);
}
// 检查是否需要绑定KwaiId
function checkNeedBindId() {
    const { menusMap, userInfo } = globalStore;
    const { needBindUser } = menusMap[route?.name] || {};
    state.needBindId = needBindUser || ['publish'].includes(route?.name);
    // 如果uid存在或不需要绑定uid的页面，直接关闭dialog
    if (!state.needBindId || userInfo?.uid) {
        bindingIdDialogRef.value?.switchDialog(false);
    }
    // 如uid不存在且需要绑定
    if (state.needBindId && !userInfo?.uid) {
        bindingIdDialogRef.value?.switchDialog();
    }
}
</script>

<style scoped lang="scss">
.layout_header {
    box-sizing: border-box;
    position: relative;
    height: $header_height;
    background: #ffffff;
    z-index: 100;
    padding: 0 16px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
}
.layout_container__wrapper {
    height: calc(100vh - $header_height);
    @include rwd-m() {
        height: calc(var(--vh) * 100 - $header_height);
    }
    background-color: #fff;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
    overflow-y: overlay;
    .aside {
        box-shadow: 0px 2px 4px #ebedf0;
        z-index: 99;
        @include rwd-m {
            display: none;
        }
    }
    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        cursor: pointer;
        transition: all 0.2s;
        border-radius: 4px;
        &:hover {
            background-color: #666;
        }
    }
    .layout_container {
        width: $page_width;
        margin: 0 auto;
    }
    :deep(.ks-main) {
        @include rwd-m() {
            padding: 16px;
        }
    }
}
</style>
