import { defineStore } from 'pinia';
import {
    emailPasswordLogin,
    saveTokenInIframe,
    requestMobileCode,
    passwordLogin,
    requestEmailCode,
    emailRegister,
    smsCodeRegister,
    resetPasswordByEmail,
    resetPasswordByPhone,
    logout,
	checkPassStrength,
	checkPhoneAvailability,
	checkEmailAvailability
} from '@ks/general-sso';
import { Message } from '@ks/kwai-ui';
import { getCookieValue } from '@/lib/utils';
import { ENV, getEnv } from '@/common/env';
import { SSO } from '@/common/enum';
import { ssoInfraLogout } from '@/services';

export const getLogIn = () => {
    return (!!getCookieValue('userId') && !!getCookieValue(`${SSO.SID}_ph`)) || [ENV.DEV, ENV.PROXY].includes(getEnv());
};
export const useSSOStore = defineStore('sso', {
    state: () => ({
        logIn: getLogIn(),
    }),
    actions: {
        setLoginStatus() {
            this.logIn = getLogIn();
        },
        async emailLogin(payload) {
            try {
                const data = await emailPasswordLogin({
                    ...this.emailPayload(payload),
                });
                if (data?.error_msg) {
                    Message.error(data?.error_msg);
                    return;
                }
                const result = await this.saveToken(data);
                this.setLoginStatus();
                return result;
            } catch (e) {
                Message.error(e?.error_msg || 'Login Error');
                throw e;
            }
        },
        async mobileLogin(payload) {
            try {
                const data = await passwordLogin({
                    ...this.mobilePayload(payload),
                    createId: false,
                });
                if (data?.error_msg) {
                    Message.error(data?.error_msg);
                }
                const result = await this.saveToken(data);
                this.setLoginStatus();
                return result;
            } catch (e) {
                Message.error(e?.error_msg || 'Login Error');
                throw e;
            }
        },
        login(payload, isMobile = false) {
            if (isMobile) {
                return this.mobileLogin(payload);
            }
            return this.emailLogin(payload);
        },
        async getEmailCode(payload) {
            const data = await requestEmailCode({
                email: payload?.email,
                type: 358, // 邮箱验证码类型，
            });
            if (data?.error_msg) {
                Message.error(data?.error_msg);
            }
        },
        async getMobileCode(payload) {
            const { phone, countryCode } = this.mobilePayload(payload);
            const data = await requestMobileCode({
                phone,
                countryCode,
                type: 2001801, // 邮箱验证码类型
            });
            if (data?.error_msg) {
                Message.error(data?.error_msg);
            }
        },
        async getVerificationCode(payload, isMobile = false) {
            if (isMobile) {
                await this.getMobileCode(payload);
            } else {
                await this.getEmailCode(payload);
            }
        },
        async mobileRegister(payload) {
            try {
                const data = await smsCodeRegister({
                    ...this.mobilePayload(payload),
                });
                if (data?.error_msg) {
                    Message.error(data?.error_msg);
                    return {};
                }
                const { result } = await this.saveToken(data);
                return {
                    ...data,
                    result,
                };
            } catch (e) {
                Message.error(e?.error_msg || 'Register Error');
            }
        },
        async emailRegister(payload) {
            try {
                const data = await emailRegister({
                    ...this.emailPayload(payload),
                });
                if (data?.error_msg) {
                    Message.error(data?.error_msg);
                    return {};
                }
                const { result } = await this.saveToken(data);
                return {
                    ...data,
                    result,
                };
            } catch (e) {
                Message.error(e?.error_msg || 'Register Error');
            }
        },
        async register(payload, isMobile = false) {
            let result;
            if (isMobile) {
                result = await this.mobileRegister(payload);
                return result;
            }
            result = await this.emailRegister(payload);
            return result;
        },
        resetPassword(payload, isMobile = false) {
            if (isMobile) {
                return this.mobileResetPassword(payload);
            }
            return this.emailResetPassword(payload);
        },
        async mobileResetPassword(payload) {
            try {
                const data = await resetPasswordByPhone({
                    ...this.mobilePayload(payload),
                });
                if (data?.error_msg) {
                    Message.error(data?.error_msg);
                    return {};
                }
                return data;
            } catch (e) {
                Message.error(e?.error_msg || 'Register Error');
            }
        },
        async emailResetPassword(payload) {
            try {
                const data = await resetPasswordByEmail({
                    ...this.emailPayload(payload),
                });
                if (data?.error_msg) {
                    Message.error(data?.error_msg);
                    return {};
                }
                return data;
            } catch (e) {
                Message.error(e?.error_msg || 'Register Error');
            }
        },
        mobilePayload(payload) {
            return {
                phone: payload?.phone,
                countryCode: `+${payload?.countryCode}`,
                smsCode: payload?.code,
                password: payload?.password,
                setCookie: true,
            };
        },
        emailPayload(payload) {
            return {
                email: payload?.email,
                emailCode: payload?.code,
                password: payload?.password,
                setCookie: true,
            };
        },
        async saveToken(data) {
            const saveTokenInIframeResult = await saveTokenInIframe({
                ...data,
                stsUrl: '/rest/infra/sts', // 种植登录态 Cookie 的地址，一般是业务方域名 + /rest/infra/sts，比如 https://www.kuaishou.com/rest/infra/sts
                // stsUrl: 'https://creatormarketplace.kwai.com/rest/infra/sts', // 种植登录态 Cookie 的地址，一般是业务方域名 + /rest/infra/sts，比如 https://www.kuaishou.com/rest/infra/sts
            } as any);
            return saveTokenInIframeResult;
        },
        // 由于域名不是kwai的域名，账号中台的logout无法把业务域名下的cookie清除
        async logoutBusiness() {
            try {
                ssoInfraLogout({
                    sid: SSO.SID,
                });
            } catch {
                //
            }
        },
        async logout() {
            try {
                this.logoutBusiness();
                await logout();
            } finally {
                document.cookie = 'userId=; Domain=.kuaishou.com';
                window.location.href = `${window.location.origin}/login`;
            }
        },
		async checkPasswordStrength(payload) {
			// 校验密码强度
            try {
                const data = await checkPassStrength({
                    password: payload?.password,
                });
                if (data?.error_msg) {
                    Message.error(data?.error_msg);
                    return false;
                }
				return true;
            } catch (e) {
                Message.error(e?.error_msg || 'Low Password Strength');
				return false;
            }
        },
		async checkAccountAvailability(payload, isMobile = false) {
			// 校验手机/邮箱是否可用
            try {
				let data:any = {};
				if (isMobile){
					const { phone, countryCode } = this.mobilePayload(payload);
					data = await checkPhoneAvailability({
						phone,
						countryCode,
					});
				} else {
					data = await checkEmailAvailability({
						email: payload?.email,
					});
				}

                if (data?.error_msg) {
                    Message.error(data?.error_msg);
                    return false;
                }
				return true;
            } catch (e) {
                Message.error(e?.error_msg || 'This account has been registered');
				return false;
            }
        },
    },
});
