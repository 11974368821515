import { createStore } from 'vuex';
import localsModules from './modules/locals';
import accountModules from './modules/account';
import photoModules from './modules/photo';
import publishModules from './modules/publish';
import intelligentModules from './modules/intelligent';
import selectorsModules from './selectors';
import collectionModules from './modules/collection';
import translateModules from './modules/translate';

export default createStore({
    state: {

    },
    mutations: {},
    actions: {},
    modules: {
        localsModules,
        selectorsModules,
        translateModules,
    },
});