import { start, getAppsData, preFetch } from '@kwr/micro';
import '@kwr/ui/dist/components/image';
import { getMicroApps } from '@/services';
import { isMicroPath, isMicroReg } from './microPath';

import MircoPage from '@/components/micro/index.vue';

import { ENV, getEnv } from '@/common/env';
import { safeParse } from './utils';
import Empty from '@/components/Empty.vue';

const getMicroAppsUrl = `${getMicroApps}?mainAppId=${microMainAppId()}`;
// 读取sports低代码配置
function microMainAppId() {
    const stagingAppId = '7aqx3d83'; // 7aqx3d83  ijsznkxs
    const onlineAppId = 'zg5esg62';
    const prtAppId = 'ijsznkxs';
    let mainAppId = '';
    if (getEnv() === ENV.ONLINE) {
        mainAppId = onlineAppId;
    } else if (getEnv() === ENV.PRT) {
        mainAppId = prtAppId;
    } else {
        mainAppId = stagingAppId;
    }
    return mainAppId;
}
export const setMicroConfig = router => {
    start({
        config: {
            url: getMicroAppsUrl,
            proxy: true,
            maxRetryTimes: 3,
            businessName: 'cutmotion-micro',
        },
        app: {
            globalAssets: {
                js: [
                    'https://f2.eckwai.com/udata/pkg/eshop/fangzhou/pub/pkg/reat-18.0.0/react.production.min.js',
                    'https://f2.eckwai.com/udata/pkg/eshop/fangzhou/pub/pkg/react-dom-18.0.0/react-dom.production.min.js',
                    'https://f2.eckwai.com/udata/pkg/eshop/fangzhou/pub/pkg/moment-2.9.1/moment.min.js',
                    'https://f2.eckwai.com/udata/pkg/eshop/fangzhou/pub/pkg/mybricks/rxui-1.0.86/index.js',
                    'https://f2.eckwai.com/udata/pkg/eshop/fangzhou/pub/pkg/m-ui/1.11.1/locale/zh_CN.js',
                    'https://ali-ec.static.yximgs.com/udata/pkg/eshop/fangzhou/pub/pkg/antd-4.21.6/antd.min.js',
                    'https://ali-ec.static.yximgs.com/udata/pkg/eshop/fangzhou/pub/pkg/ant-design/icons-4.7.0/dist/index.umd.min.js',
                    'https://f2.eckwai.com/udata/pkg/eshop/fangzhou/pub/pkg/ant-design/charts-1.2.14/charts.min.js',
                ],
                css: ['https://ali-ec.static.yximgs.com/udata/pkg/eshop/fangzhou/pub/pkg/antd-4.21.6/antd.min.css'],
            },
        },
    });
    getMicroData(router);
};

export const getMicroData = router => {
    getAppsData()
        .then(initRoute.bind(this, router))
        .catch(e => {
            // eslint-disable-next-line no-console
            console.error(e);
        });
};

async function initRoute(router, appData: any) {
    const pretchApps: {
        name: string;
        url: string;
    }[] = [];
    try {
        appData?.forEach(route => {
            const { path, name, appConfig, version, releaseTag } = route;
            const { url } = route;
            let extra = {} as any;
            let config = {} as any;

            if (appConfig) {
                config = safeParse(appConfig, {});
                extra = config?.extra;
            }

            const { baseroute, prefetch } = config;

            // 微模块
            if (isMicroPath(path)) {
                router.addRoute({
                    name,
                    path,
                    props: {
                        name,
                        releaseTag,
                        version,
                        url,
                        extra,
                        baseroute,
                        path,
                    },
                    component: MircoPage,
                });

                // micro 非noah页面 不预加载
                if (prefetch && !isMicroReg(path)) {
                    pretchApps.push({
                        name,
                        url,
                    });
                }
            }
        });
        if (pretchApps.length) {
            preFetch(pretchApps);
        }
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
    }

    router.addRoute({ path: '/:pathMatch(.*)*', name: 'NotFound', component: Empty });

    if (isMicroPath(router.currentRoute.value.path)) {
        router.replace(router.currentRoute.value.fullPath);
    }
}
