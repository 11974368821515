<template>
    <div class="codebox">
        <div v-for="item in DEFAULT_LEN" :key="item" class="codebox_field__wrap">
            <input
                :ref="el => setRefs(el, item)"
                :tabindex="item"
                type="input"
                maxlength="1"
                autocomplete="false"
                autocapitalize="off"
                spellcheck="false"
                @click="textSelect($event.target)"
                @keydown="handleKeyDown($event, item - 1)"
                @input="handleChange($event, item - 1)" />
        </div>
    </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, reactive } from 'vue';

const emit = defineEmits(['close', 'submit']);
const DEFAULT_LEN = 6; // 设计有布局要求，中间有横线；
const state = reactive({
    inputRefs: [],
    code: [],
});
function handleChange(e, i) {
    const value = e.target.value.trim();
    if (state.code[i] !== value && value) {
        focusOn(i + 1);
    }
    if (state.code[i] !== value && value === '') {
        focusOn(i - 1);
    }
    state.code[i] = value;
}
function setRefs(e, i) {
    if (e) {
        state.inputRefs[i - 1] = e;
    }
}
function handleKeyDown(e, i) {
    const inputElement = e.target;
    switch (e.keyCode) {
        case 8: // 删除
            if (e.target.value === '') {
                focusOn(i - 1);
            }
            break;
        case 37: // 左
        case 38: // 上
            removeDefaultBehavior(e);
            if (getPrevBox(i)) {
                focusOn(i - 1);
            } else {
                focusOn(i);
            }
            break;
        case 39: // 右
        case 40: // 下
            removeDefaultBehavior(e);
            if (getNextBox(i)) {
                focusOn(i + 1);
            } else {
                focusOn(i);
            }
            break;
        default:
            textSelect(inputElement);
    }
}

function getPrevBox(i) {
    return state.inputRefs[i - 1];
}
function getNextBox(i) {
    return state.inputRefs[i + 1];
}
function focusOn(i) {
    const element = state.inputRefs[i];
    if (element) {
        element.focus();
    }
}
function removeDefaultBehavior(event) {
    if (event.preventDefault) event.preventDefault(); // 标准技术

    if (event.stopPropagation) {
        event.stopPropagation();
    }
    return false; // 用于处理使用对象属性注册的处理程序
}
function textSelect(e) {
    const start = 0;
    const end = e.value.length;
    e.setSelectionRange(start, end);
    e.focus();
}

function validate() {
    const valid = state.code.filter(item => item !== '')?.length === DEFAULT_LEN;
    return {
        valid,
        data: state.code?.join(''),
    };
}
onMounted(() => {
    window.addEventListener('paste', setPasteCode);
});
onUnmounted(() => {
    window.removeEventListener('paste', setPasteCode);
});
function setPasteCode(e) {
    const clipData = e.clipboardData || window.clipboardData;
    const value = clipData.getData('text/plain');
    if (/^\d{6}$/.test(value)) {
        state.code = value.split('');
        state.inputRefs.forEach((item, index) => {
            item.value = value[index];
        });
    }
}
defineExpose({
    validate,
});
</script>

<style scoped lang="scss">
.codebox {
    display: flex;
    justify-content: center;
}
.codebox_field__wrap {
    display: inline-block;
    width: 56px;
    height: 56px;
    margin-right: 12px;
    border-radius: 4px;
    position: relative;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #000000;
    @include rwd-m {
        width: 10.386vw;
        height: 10.386vw;
        margin-right: 2.174vw;
    }
    &:nth-child(3) {
        margin-right: 36px;
        @include rwd-m {
            margin-right: 3.733vw;
        }
        &:after {
            position: absolute;
            top: 50%;
            content: '';
            display: inline-block;
            width: 20px;
            right: -28px;
            height: 0px;
            border-top: 1px solid #cccccc;
            @include rwd-m {
                width: 2.667vw;
                right: -3.733vw;
            }
        }
    }
    &:last-child {
        margin-right: 0;
    }
    input {
        width: inherit;
        height: inherit;
        line-height: inherit;
        text-align: center;
        padding: 0;
        margin: 0;
        vertical-align: baseline;
        border: none;
        outline: none;
        color: #494949;
        border: 1px solid #d6d6d6;
        border-radius: 4px;
        &:focus {
            border: 1px solid #326bfb;
        }
    }
}
</style>
