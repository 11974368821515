export const PRODUTION_CDN_PATH = 'https://cdn-static.kwai.net/kos/s101/nlav11312/cutmotions';
export const TEST_CDN_PATH = 'https://cdn-static.kwai.net/kos/s101/nlav11312/cutmotions/test';

export enum HOST {
    development = '',
    localhost = 'dev.cutmotions.staging.kuaishou.com',
    online = 'www.cutmotions.com',
}
export enum ENV {
    ONLINE = 'online',
    STAGING = 'staging',
    PROXY = 'proxy',
    DEV = 'dev',
    PRT = 'prt',
}

export function getEnv() {
    const { host } = window.location;
    if (host === HOST.online) {
        return ENV.ONLINE;
    }
    if (host.includes('dev')) {
        return ENV.DEV;
    }
    if (host.includes('prt')) {
        return ENV.PRT;
    }
    if (host.includes('kproxy.corp.kuaishou')) {
        return ENV.PROXY;
    }
    return ENV.STAGING;
}

export function getBaseUrl() {
    const { host } = window.location;
    // staging环境
    if (host.includes('staging.kuaishou.com')) {
        return 'https://ksi18nid.staging.kuaishou.com';
    }
    return 'https://id.kwai-pro.com';
}
