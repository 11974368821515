import { getCurrentInstance } from 'vue';
import { Message } from '@ks/kwai-ui';
import moment from 'moment';
import KwaiVideoQosH5 from '@ks/kwai-video-qos-h5/dist/light';
import { VIDEO_ITEM } from './const';

import { $weblog } from '@/lib/weblog';

export const getCookieValue = (name: string) => {
    const arr = document.cookie.match(new RegExp(`(^| )${name}=([^;]*)(;|$)`));

    if (arr != null) {
        return decodeURIComponent(arr[2]);
    }
};

export function toCapitalized(str = '') {
    return str.replace(/^\S/, s => s.toUpperCase());
}
// 中文使用全角括号，英文使用半角括号
export function getBracketContent(value) {
    const $k18n = getCurrentInstance()?.appContext.config.globalProperties.$k18n;
    if (value || value === 0) {
        return `${$k18n('（')}${value}${$k18n('）')}`;
    }
}
// 复制文案
export const copyData = (label: string, value: string) => {
    const transfer = document.createElement('input');
    document.body.appendChild(transfer);
    transfer.value = `${value}`;
    transfer.select();
    if (document.execCommand('copy')) {
        document.execCommand('copy');
    }
    Message(`复制${label}成功`);
    document.body.removeChild(transfer);
};
// 查询url后传递的字符串参数 json
export const getUrlParams = (urlSearch?: string, config?: { decodeBool: boolean }): object => {
    const urlSearchList = urlSearch && /\?.*/.exec(urlSearch);

    urlSearch = (urlSearchList && urlSearchList[0]) || location.search;
    // 取得查询字符串并去掉开头的问号，location.search返回?以后的字符串
    if (urlSearch.indexOf('?') !== -1) {
        urlSearch = urlSearch.slice(1);
    }

    const queryString = urlSearch.length ? urlSearch : '';
    const result: any = {};
    const items = queryString.length ? queryString.split('&') : [];

    // 逐个将每一项添加到args对象中
    items.forEach((item: any) => {
        item = item.split('=');
        // url后的参数浏览器会对其进行编码（encodeURIComponent），此处进行解码操作。
        const name = window.decodeURIComponent(item[0]);
        let value: string | boolean = window.decodeURIComponent(item[1]);
        if (name && name.length && !result[name]) {
            // 判断是否需要对URL中的布尔值进行转换
            if (config?.decodeBool) {
                if (value === 'true') value = true;
                if (value === 'false') value = false;
            }
            // 避免重复参数被覆盖
            result[name] = value;
        }
    });

    return result;
};
export function formatSeconds(value: any) {
    // 处理字幕中的时间
    let theTime = parseInt(value, 10); // 秒
    let theTime1 = 0; // 分
    let theTime2 = 0; // 小时
    if (theTime > 60) {
        theTime1 = parseInt(theTime / 60);
        theTime = parseInt(theTime % 60);
        if (theTime1 > 60) {
            theTime2 = parseInt(theTime1 / 60);
            theTime1 = parseInt(theTime1 % 60);
        }
    }

    let result = `${parseInt(theTime)}`; // 秒
    if (theTime < 10 > 0) {
        result = `0${parseInt(theTime)}`; // 秒
    } else {
        result = `${parseInt(theTime)}`; // 秒
    }

    if (theTime1 < 10 > 0) {
        result = `0${parseInt(theTime1)}:${result}`; // 分，不足两位数，首位补充0，
    } else {
        result = `${parseInt(theTime1)}:${result}`; // 分
    }
    if (theTime2 > 0) {
        result = `${parseInt(theTime2)}:${result}`; // 时
    }
    return result;
}

export const downloadBlob = (blob, fileName) => {
    try {
        // 谷歌浏览器 创建a标签 添加download属性下载
        const href = window.URL.createObjectURL(blob);
        const downloadElement = document.createElement('a');
        downloadElement.href = href;
        downloadElement.target = '_blank';
        downloadElement.download = fileName;
        document.body.appendChild(downloadElement);

        downloadElement.click(); // 点击下载
        document.body.removeChild(downloadElement); // 下载完成移除元素
        window.URL.revokeObjectURL(href); // 释放掉blob对象
    } catch (e) {
        console.log('下载失败');
    }
};
export function downloadOriginVideo(url, title = ''): Promise<any> {
    return fetch(url)
        .then(response => {
            return response.blob();
        })
        .then(res => {
            const blod = new Blob([res]);
            const name = `${title}-${Date.now()}.mp4`;
            downloadBlob(blod, name);
            return {
                result: 1,
            };
        });
}
export const openPage = (path: string, query?: any, type?: boolean) => {
    let url = path;
    if (query) {
        let paramsStr = Object.keys(query).reduce((str, current) => {
            str += `&${current}=${query[current]}`;
            return str;
        }, '');

        if (path.indexOf('?') === -1) {
            paramsStr = paramsStr.replace('&', '?');
        }
        url = path + paramsStr;
    }
    if (type) {
        window.open(url);
    } else {
        window.open(url, '_blank');
    }
};
// 获取平台所运行的浏览器特性数据
export const getBrowserInfo = () => {
    const Sys = {
        browser: '',
        ver: '',
    } as { browser: string | undefined; ver: string | undefined };
    const ua = navigator.userAgent.toLowerCase();
    const re = /(msie|firefox|chrome|opera|version).*?([\d.]+)/;
    const browserInfo = ua.match(re);
    Sys.browser = browserInfo?.[1].replace(/version/, '\'safari');
    Sys.ver = browserInfo?.[2];
    return Sys;
};

export function deleteNullOrBlankValue(params: any) {
    Object.keys(params).map((key: any) => {
        if (params[key] === null || params[key] === '' || params[key] === undefined) {
            delete params[key];
        }
    });
}
export function formatVideoList(videoList: VIDEO_ITEM[]) {
    videoList.map((video: VIDEO_ITEM) => {
        if (+video.publishTime) {
            const publishTime = moment(+video.publishTime).toString();
            video.publishTime = moment(+video.publishTime).format('YYYY/MM/DD HH:mm:ss') + publishTime.substr(publishTime.lastIndexOf(' '));
        } else {
            video.publishTime = '-';
        }
        video.popoverVisible = false;
    });
    return videoList;
}
export function formatMaterialList(materialList: VIDEO_ITEM[]) {
    materialList.map((video: VIDEO_ITEM) => {
        video.popoverVisible = false;
    });
    return materialList;
}
export const timeFormat = (time: string | number, format = 'YYYY/MM/DD hh:mm:ss') => moment(time).format(format);
export const formateList = (list: any) => {
    return list?.length
        ? list.map((v: any) => {
              v.createTime = timeFormat(v.createTime);
              if (v.updateTime) {
                  v.updateTime = timeFormat(v.updateTime);
              }
              return v;
          })
        : [];
};
// 多参数下载
export const getDownload = (url: any = '', options: any = {}) => {
    options.body = options.body || {};

    const params: any = {
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            ...(options.headers || {}),
        },
        credentials: 'include',
        mode: 'cors',
        method: 'POST',
        body: JSON.stringify(options.body || {}),
    };

    let fileName;

    fetch(url, params)
        .then(res => {
            fileName = res.headers.get('content-disposition')?.split('filename=')[1];
            return res && res.blob();
        })
        .then(res => {
            // 创建隐藏的可下载链接
            const aLink = document.createElement('a');
            aLink.download = fileName; // 设置a标签的下载名字
            aLink.style.display = 'none';
            aLink.href = URL.createObjectURL(res);
            // 触发点击
            document.body.appendChild(aLink);
            aLink.click();
            // 然后移除
            document.body.removeChild(aLink);
        })
        .catch(() => {});
};
export function formatSecondToHms(value: any) {
    // 将秒处理为xxhxxmxxs
    let theTime = parseInt(value, 10); // 秒
    let theTime1 = 0; // 分
    let theTime2 = 0; // 小时
    if (theTime > 60) {
        theTime1 = parseInt(theTime / 60);
        theTime = parseInt(theTime % 60);
        if (theTime1 > 60) {
            theTime2 = parseInt(theTime1 / 60);
            theTime1 = parseInt(theTime1 % 60);
        }
    }

    let result = `${parseInt(theTime)}s`; // 秒
    if (theTime1 > 0) {
        result = `${parseInt(theTime1)}m${result}`; // 分
    }
    if (theTime2 > 0) {
        result = `${parseInt(theTime2)}h${result}`; // 时
    }
    return result;
}
export function initVideoQos(qos: any, htmlRef) {
    // 初始化卡顿率插件
    qos.value = new KwaiVideoQosH5({
        autoCountRetry: true,
        debug: false,
        logEnv: 'production',
        logBaseConfig: { product_name: 'CREATIVE', env: 'oversea' } as any,
    } as any);

    qos.value.on('report', e => {
        if (e.type === 'summary' && e.value?.play_start_time) {
            // 上报至kwaibi汇总统计
            $weblog.sendClick({ action: 'SUMMARY', params: e.value });
        }
    });

    qos.value.attachMedia(htmlRef);
}
export function destroyVideoQos(qos: any) {
    qos.value?.sendSummary();
    qos.value?.destroy();
    qos.value = null;
}
export const safeParse = (jsonString, defaultValue) => {
    try {
        return JSON.parse(jsonString);
    } catch (error) {
        return defaultValue || {};
    }
};
