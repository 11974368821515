export const LANGUAGE_STORAGE_KEY = 'LOCALE';
export const LANGUAGE = {
    en: 'en',
    zh: 'zh',
    ar: 'ar',
    es: 'es',
    id: 'id',
    // pt: 'pt',
    tr: 'tr',
	pt: 'pt-BR',
};

export const SSO = {
    SID: 'ksi18n.noah.intelligent',
};
export const MATERIAL_STATUS = {
    normal: 100,
    offLine: 200,
    new: 300,
	hot: 400,
};
// 响应式分辨率判断，如果修改，注意修改rmd-m函数的值
export enum responsiveEnum {
    m = 500,
}
