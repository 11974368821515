import { defineStore } from 'pinia';
import { Message } from '@ks/kwai-ui';
import { getKconfSettingApi, getUserInfoApi } from '@/services';
import { oldMenuKeyMap } from '@/layout/menu/const';

export enum UserTypeEnum {
    ERROR = -1,
    CREATOR, //
    TRANSLATOR,
}
export enum PlatformVersionEnum {
    // 平台版本
    OLD = 'old',
    NEW = 'new',
}
export const useGlobalStore = defineStore('global', {
    state: () => ({
        userInfo: {
            bucket: 0,
            userId: '',
            uid: '',
            status: 0,
            userType: UserTypeEnum.CREATOR,
            kwaiIdInfos: [],
            resources: [],
            userName: '',
        },
        menusMap: {},
        isInit: false, // 是否初始化加载了userInfo与kconf数据
        kconfConfig: {} as { [key: string]: any },
        isMobileMode: false,
        platformVersion: PlatformVersionEnum.OLD, // 全局版本值
        globalLayoutLoading: false, // 登录页面获取info信息前的loading
        versionBtnShow: false, // 版本号按钮是否展示
    }),
    actions: {
        jumpToLogin(errorMessage) {
            // 如果权限获取失败，被拦截等问题
            const pathname = window.location.pathname;
            // 避免死循环
            if (pathname !== '/login') {
                Message.error(errorMessage);
                window.open('/login', '_self');
            }
        },
        async getUserInfo() {
            try {
                this.globalLayoutLoading = true;
                const { data, result }: any = await getUserInfoApi();
                this.globalLayoutLoading = false;
                this.userInfo = data;
                this.getMenuMap();
                return result;
            } catch (e) {
                this.globalLayoutLoading = false;
                this.jumpToLogin('Please login');
            }
        },
        getMenuMap() {
            this.userInfo?.menus?.forEach(item => {
                this.menusMap[item.key] = item;
                if (!oldMenuKeyMap.includes(item.key)) {
                    this.versionBtnShow = true;
                }
            });
        },
        async getAllConfig() {
            const { data, result, msg }: any = await getKconfSettingApi();
            if (result === 1) {
                this.kconfConfig = data;
            } else {
                Message.error(msg);
            }
        },
        // 是否需要绑定KwaiId
        needToBindKwaiId() {
            const { uid, userType } = this.userInfo || {};
            return !uid && userType === UserTypeEnum.CREATOR;
        },
        // 是否需要设置用户类型
        needSetUserType() {
            const { userType } = this.userInfo || {};
            return userType === UserTypeEnum.ERROR;
        },
    },
});
