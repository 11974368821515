<template>
    <kwr-micro-app
        v-if="url && name"
        :errortext="$k18n('当前访问遇到了问题，可刷新重试下')"
        :errorbtntext="$k18n('刷新')"
        :name="name"
        :url="url"
        :extra="extra"
        :baseroute="baseroute"
        :debug="debug"
        :ondatachange="handleDataChange" />
    <Empty v-else />
</template>
<script lang="ts" setup>
import { watch, computed, toRefs, ref, onMounted, getCurrentInstance } from 'vue';
import { onBeforeRouteUpdate, useRouter, useRoute } from 'vue-router';
import '@kwr/ui/dist/themes/kwr.css';
import '@kwr/ui/dist/index';
import '@kwr/ui/dist/components/editor';
import '@kwr/ui/dist/components/preview';
import { microApp } from '@kwr/micro';
import { useStore } from 'vuex';
import Empty from '@/components/Empty.vue';
import { ENV, HOST, getEnv } from '@/common/env';
import { useGlobalStore } from '@/stores';

const globalStore = useGlobalStore();

const router = useRouter();
const route = useRoute();

const store = useStore();
const defaultLang = computed(() => store.state?.localsModules.locals);
const { $k18n }: any = (getCurrentInstance() as any).appContext.config.globalProperties;

const props = defineProps({
    name: { type: String, required: true },
    url: { type: String, required: true },
    version: { type: String, required: true },
    releaseTag: { type: String, required: true },
    baseroute: { type: String, required: true },
    extra: {
        type: Object,
        required: false,
        default: () => ({
            disableMemoryRouter: true,
        }),
    },
});

const { name, url, baseroute, extra } = toRefs(props);

const debug = ref([ENV.DEV, ENV.PROXY, ENV.PRT, ENV.STAGING].includes(getEnv()));

const isMicroPath = path => {
    return path?.includes('/cutmotionMicro/');
};

onMounted(() => {
    const path = route.path;
    // 初始触发四次
    if (path) {
        microApp.setData(name.value, { type: 'path', data: { path, query: route.query, userId: globalStore.userInfo.userId } });
    }
});

const handleDataChange = e => {
    // 来自子应用的数据

    const data = e.detail.data;
    if (data?.selection?.path) {
        router.push({
            path: data.selection.path,
            query: {
                taskId: data.selection?.taskId,
                status: data.selection?.status,
                type: data.selection?.type,
            },
        });
    }
};

onBeforeRouteUpdate((to, from, next) => {
    if (to.path) {
        if (isMicroPath(to.path)) {
            microApp.setData(name.value, { type: 'path', data: { path: to.path } });
        }
    }

    next();
});
</script>

<style lang="scss" scoped>
kwr-iframe-app,
kwr-micro-app {
    width: 100%;
    height: 100%;
}
</style>
