<template>
    <component :is="active ? item.hoverIcon : item.icon" v-if="item.icon" />
    <component :is="active ? 'IconColourManageActive' : 'IconColourManageNormal'" v-else />
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import {
    IconColourManageActive,
    IconColourManageNormal,
    IconColourVideoActive,
    IconColourVideoNormal,
    IconColourUserActive,
    IconColourUserNormal,
    IconColourToolsActive,
    IconColourToolsNormal,
    IconColourTargetActive,
    IconColourTargetNormal,
    IconColourQuitActive,
    IconColourQuitNormal,
    IconColourPieActive,
    IconColourPieNormal,
    IconColourPageActive,
    IconColourPageNormal,
    IconColourMusicActive,
    IconColourMusicNormal,
    IconColourMonitorActive,
    IconColourMonitorNormal,
    IconColourGoodsActive,
    IconColourGoodsNormal,
    IconColourGiftActive,
    IconColourGiftNormal,
    IconColourConfigActive,
    IconColourConfigNormal,
    IconColourCardActive,
    IconColourCardNormal,
    IconColourXingGray,
    IconColourXing,
    IconColourFootballActive,
    IconColourFootballNormal,
    IconColourActiveNormal,
    IconColourReviewActive,
    IconColourMerchantActive,
    IconColourMerchantNormal,
} from '@kibt/pro-icons';

// ！！！最好不要删除，删除的话要仔细check，菜单里会进行icon配置，然后通过此组件component渲染，
export default defineComponent({
    components: {
        IconColourManageActive,
        IconColourManageNormal,
        IconColourVideoActive,
        IconColourVideoNormal,
        IconColourUserActive,
        IconColourUserNormal,
        IconColourToolsActive,
        IconColourToolsNormal,
        IconColourTargetActive,
        IconColourTargetNormal,
        IconColourQuitActive,
        IconColourQuitNormal,
        IconColourPieActive,
        IconColourPieNormal,
        IconColourPageActive,
        IconColourPageNormal,
        IconColourMusicActive,
        IconColourMusicNormal,
        IconColourMonitorActive,
        IconColourMonitorNormal,
        IconColourGoodsActive,
        IconColourGoodsNormal,
        IconColourGiftActive,
        IconColourGiftNormal,
        IconColourConfigActive,
        IconColourConfigNormal,
        IconColourCardActive,
        IconColourCardNormal,
        IconColourXingGray,
        IconColourXing,
        IconColourFootballActive,
        IconColourFootballNormal,
        IconColourActiveNormal,
        IconColourReviewActive,
        IconColourMerchantActive,
        IconColourMerchantNormal,
    },
    props: {
        item: {
            type: Object,
            default: () => ({}),
        },
        active: {
            type: Boolean,
            default: false,
        },
    },
});
</script>
