import { Commit } from 'vuex';
import { http } from '@/lib/request';
import { baseInfoApi } from '@/services';

const getBaseInfo = 'getBaseInfo';

export default {
    namespaced: true,
    state: {
        getBaseInfo: {},
    },
    mutations: {
        [getBaseInfo](state: any, payload: any) {
            state[getBaseInfo] = payload || {};
        },
    },
    actions: {
        async [getBaseInfo]({ commit }: { commit: Commit }, payload: any) {
            const response: any = await http(baseInfoApi, payload, {});

            if (response?.result === 1) {
                commit('getBaseInfo', response.data || {});
            }

            return response;
        },
    },
};
