import KwaiLog from '@kibt/weblogger/lib/log.browser';
import AutoPV from '@ks/weblogger/lib/plugins/autopv';
import { ENV, getEnv } from '@/common/env';

// 初始化 weblogger
export const $weblog = new KwaiLog(
    {
        // 项目中有变量替换且值
        env: getEnv() === ENV.ONLINE ? 'oversea' : 'logger',

        radar: {
            projectId: '614671d78h', // 雷达平台上的唯一标识
            sampling: 1,
        },
        plugins: [new AutoPV()],
    },
    {
        product_name: 'CREATIVE',
    }
);
