<template>
    <ks-dialog v-model="state.visible" width="580px" :show-close="false" :close-on-click-modal="false">
        <div v-show="state.step === StepEnum.BINDING_ID" class="content_wrap">
            <h2>
                <k18n>输入绑定ID</k18n>
            </h2>
            <div class="tips mt-12 mb-24">Enter UID, and we'll send a captcha to your APP to make sure you do it yourself.</div>
            <BindingId ref="bindIdRef" :form-data="formData" />
        </div>
        <div v-show="state.step === StepEnum.BINDING_ID_CODE" class="content_wrap">
            <div class="back mb-20" @click="handleBack">
                <IconDirectionLeftArrow class="mr-6" color="#A6AAB7" />
                <k18n>返回</k18n>
            </div>
            <h2>
                <k18n>Confirm on your phone</k18n>
            </h2>
            <div class="tips mt-12 mb-24">Code is sent to your mobile app. Please input the code and confirm your operation</div>
            <VerificationCodeBox ref="bindingIdCodeRef" />
        </div>
        <div class="button__wrapper">
            <ks-button type="primary" @click="handleClickBtn">
                <k18n>
                    {{ STEP_CONFIG[state.step]?.btnText }}
                </k18n>
            </ks-button>
        </div>
    </ks-dialog>
</template>

<script setup lang="ts">
import { inject, reactive, ref } from 'vue';
import { IconDirectionLeftArrow } from '@kibt/pro-icons';
import { Message } from '@ks/kwai-ui';
import VerificationCodeBox from '@/components/loginBox/VerificationCodeBox.vue';
import { StepEnum } from '@/pages/signup/const';
import BindingId from './BindingId.vue';
import { getUserVerifyMsgCodeApi } from '@/services';
import { RES_CODE } from '@/types/global';

const $k18n: any = inject('$k18n');
const bindIdRef = ref<any>(null);
const bindingIdCodeRef = ref<any>(null);

const state = reactive({
    visible: false,
    step: StepEnum.BINDING_ID,
});

const STEP_CONFIG = {
    [StepEnum.BINDING_ID]: {
        btnText: '下一步',
    },
    [StepEnum.BINDING_ID_CODE]: {
        btnText: '绑定',
    },
};
const formData = reactive({
    kwaiId: '',
});
function switchDialog(isShow = true) {
    state.visible = isShow;
}
function handleBack() {
    state.step--;
}
function handleClickBtn() {
    if (state.step === StepEnum.BINDING_ID) {
        sendKwaiIdCode();
        return;
    }
    if (state.step === StepEnum.BINDING_ID_CODE) {
        bindKwaiIdCode();
    }
}
async function sendKwaiIdCode() {
    const { valid } = (await bindIdRef.value?.validate()) || {};
    if (valid) {
        state.step++;
    }
}
async function bindKwaiIdCode() {
    const { valid, data } = (await bindingIdCodeRef.value?.validate()) || {};
    if (valid) {
        const { result } = await getUserVerifyMsgCodeApi({
            kwaiId: formData.kwaiId,
            msgCode: data,
        });
        if (result === RES_CODE.SUCCESS) {
            Message.success($k18n('Kwai Id 绑定成功'));
            window.location.reload();
        }
    } else {
        Message.error($k18n('请输入验证码'));
    }
}

defineExpose({
    switchDialog,
});
</script>

<style scoped lang="scss">
.content_wrap {
    min-height: 320px;
}
.button__wrapper {
    margin-bottom: 32px;
    display: flex;
    justify-content: flex-end;
}
</style>
