export const microPathReg = ['/cutmotionMicro/'];

export const MicroAllAppPaths = ['/cutmotionMicro/:page*'];

const regexp = /\/(cutmotionMicro)(.)*/;

export const isMicroReg = path => {
    return regexp.test(path);
};

export const isMicroPath = path => {
    return regexp.test(path) || microPathReg.some(item => path.includes(item));
};
