import { Commit } from 'vuex';

export const setLocal = 'setLocal';

const ksLocal = 'ksLocal';
const getDefaultLocals = (): string => {
    return localStorage.getItem('ksLocal') || navigator.language.split('-')[0] || 'en';
};

const setLocalStorageLocals = (value: string) => {
    localStorage.setItem(ksLocal, value);
};

export default {
    namespaced: true,
    state: () => ({
        locals: getDefaultLocals(),
    }),
    mutations: {
        [setLocal](state: any, option: any) {
            state.locals = option;
            setLocalStorageLocals(option);
        },
    },
    actions: {
        [setLocal]({ commit }: { commit: Commit }, payload: any) {
            commit(setLocal, payload);
        },
    },
};
