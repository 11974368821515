<template>
    <span class="lang_select__wrapper">
        <ProSelect
            v-model="defaultLang"
            :border="false"
            :placeholder="$k18n('请选择')"
            class="lang_select"
            :options="localStore.langs"
            @change="onChangeLang" />
    </span>
</template>

<script setup lang="ts">
import { inject, computed } from 'vue';
import { ProSelect } from '@kibt/pro-render';
import { useLocalStore } from '@/stores';

const $k18n: any = inject('$k18n');
const localStore = useLocalStore();

const props = defineProps({
    showIcon: {
        type: Boolean,
        default: true,
    },
});
const defaultLang = computed({
    get() {
        return localStore.locals;
    },
    set(val) {
        $k18n.global.changeLocale(val);
        localStore.setLocal(val);
    },
});
function onChangeLang(value) {
    localStore.setLocal(value);
    window.location.reload();
}
</script>

<style scoped lang="scss">
.lang_select__wrapper {
    .lang_select {
        width: 120px;
    }
}
</style>
