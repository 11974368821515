export enum AccountTypeEnum {
    MOBILE = 0,
    EMAIL,
}
// 流程顺序会根据值
export enum StepEnum {
    FORM, // form表单页
    CODE, // 验证码页面
    BINDING_ID, // BINDING ID页面
    BINDING_ID_CODE, // binding后发送的验证码
}
export enum LoginFormTypeEnum {
    LOGIN = 'login', // 登录
    PASSWORD = 'password', // 密码重置
    SIGNUP = 'signup', // 注册
}
export const stepEnumArr = Object.keys(StepEnum).filter(item => Number.isNaN(Number(item)));
export const STEP_CONFIG_SIGNUP = {
    [StepEnum.FORM]: {
        btnText: '下一步',
    },
    [StepEnum.CODE]: {
        btnText: '注册',
    },
    [StepEnum.BINDING_ID]: {
        btnText: '下一步',
    },
    [StepEnum.BINDING_ID_CODE]: {
        btnText: '绑定',
    },
};
export const STEP_CONFIG_PASSWORD = {
    [StepEnum.FORM]: {
        btnText: '下一步',
    },
    [StepEnum.CODE]: {
        btnText: '重置',
    },
};
export const STEP_CONFIG_LOGIN = {
    [StepEnum.FORM]: {
        btnText: '登录',
    },
};
